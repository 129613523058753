import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
	createStyles({
		navigation: {
			margin: "1em",
			textAlign: "center",
		},
		mobileNavigation: {
			height: "50px",
			margin: "0",
			display: "grid",
			gridTemplateColumns: "80% auto",
		},
		logo: {
			padding: "0.2em 1.2em",
		},
	})
);

export default useStyles;
