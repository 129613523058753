import React from "react";
import { Container, Typography } from "@material-ui/core";
import { Carousel } from "../../lib/CommonTech/Carousel/Carousel";

export const Home = (): JSX.Element => {
	return (
		<>
			<Carousel />

			<Container maxWidth="md" style={{ marginTop: "60px", marginBottom: "60px" }}>
				<Typography variant="h5" align="center">
					Spoločnosť Funny Sport Slovensko s.r.o. sa zaoberá dodávkou a montážou rôznych športových podláh, športovísk a ihrísk,
					či už vonkajších alebo vnútorných. Jedná sa o umelé športové povrchy pre jednotlivé druhy športov, ale aj pre
					viacúčelové športoviská. Našou hlavnou náplňou je montáž športových povrchov či už na báze dreva, gumy, kobercov, avšak
					hlavným artiklom je umelý trávnik. Funny Sport Slovensko s.r.o. zastupuje osvedčeného dodávateľa športových umelých
					trávnikov, holandskú firmu EDEL GRASS B.V. so sídlom v Genemuiden.
				</Typography>
			</Container>
		</>
	);
};
