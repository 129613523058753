import createMessageDescriptors from "../../CommonLib/createMessageDescriptors.ts";

export default createMessageDescriptors("PRODUCT", {
	PRODUCTS: "Produkty",
	INDOOR_SPORT_SURFACES: "Vnútorné športové povrchy",
	MULTIPURPOSE_FIELDS: "Multifunkčné ihriská",
	FOOTBALL_PITCH: "Futbalové ihriská",
	ATHLETICS: "Atletika",
	KIDS_PLAYGROUNDS: "Detské ihriská",
	OUTSIDE_FITNESS_AND_WORKOUT: "Vonkajší fitness a workout",
	MAITANANCE: "Údržba",
	IMPACT_SURFACES_AND_SAFETY_SURFACES: "Dopadové plochy a bezpečnostné povrchy",
	DECOR_SURFACES: "Dekoračné trávniky",
});
