import React from "react";
import { Route } from "react-router-dom";
import AboutUs from "../AboutUs";
import Contact from "../Contact";
import Home from "../Home";
import ProductDetail from "../ProductDetail";
import Products from "../Products";
import { References } from "../References/References";
import routeDefinitions from "./routeDefinitions";

const Routes = (): JSX.Element => {
	return (
		<>
			<Route path={routeDefinitions.home} exact>
				<Home />
			</Route>

			<Route path={routeDefinitions.products}>
				<Products />
			</Route>

			<Route path={routeDefinitions.references}>
				<References />
			</Route>

			<Route path={routeDefinitions.aboutUs}>
				<AboutUs />
			</Route>

			<Route path={routeDefinitions.contact}>
				<Contact />
			</Route>

			<Route path={routeDefinitions.productDetail}>
				<ProductDetail />
			</Route>
		</>
	);
};

export default Routes;
