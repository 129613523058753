import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	headerTitle: {
		position: "absolute",
		height: "100%",
		width: "100%",
		color: "white",
		zIndex: 100,
		marginBottom: "8px",
		textAlign: "center",
		"&:hover": {
			color: "#78c145",
		},
	},
	textStyle: {
		backgroundColor: "#00000073",
		padding: "16px",
	},
}));

export default useStyles;
