import React from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import useStyles from "./productDetailImage.styles";
import classnames from "classnames";

export interface ProductDetailImageProps {
	img: string;
	alt: string;
}

const ProductDetailImage = ({ img, alt }: ProductDetailImageProps): JSX.Element => {
	const styles = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	return (
		<>
			<div className={styles.imgBox}>
				<img src={img} alt={alt} className={classnames(!matches ? styles.imgBoxMobile : styles.imgStyle)} />
			</div>
		</>
	);
};

export default ProductDetailImage;
