export const ProductsArray = [
	{
		id: "INDOOR_SPORT_SURFACES",
		route: "vnutorne-sportove-povrchy",
		image: require("../../assets/BA ZŠ Bieloruská.jpg"),
	},
	{
		id: "MULTIPURPOSE_FIELDS",
		route: "multifunkcne-ihriska",
		image: require("../../assets/multiIhrisko.jpg"),
	},
	{
		id: "FOOTBALL_PITCH",
		route: "futbalove-ihriska",
		image: require("../../assets/futbalovve_ihrisko.jpg"),
	},
	{
		id: "ATHLETICS",
		route: "atletika",
		image: require("../../assets/atletika.jpg"),
	},
	{
		id: "KIDS_PLAYGROUNDS",
		route: "detske-ihriska",
		image: require("../../assets/detske_Ihrisko2.jpg"),
	},
	{
		id: "OUTSIDE_FITNESS_AND_WORKOUT",
		route: "vonkajsi-fitness-workout",
		image: require("../../assets/workout.jpg"),
	},
	{
		id: "MAITANANCE",
		route: "udrzba",
		image: require("../../assets/udrzba_ihrisk.jpg"),
	},
	{
		id: "IMPACT_SURFACES_AND_SAFETY_SURFACES",
		route: "dopadove-plochy-bezpecnostne-povrchy",
		image: require("../../assets/vnutorne.jpg"),
	},
	{
		id: "DECOR_SURFACES",
		route: "dekorativne-plochy",
		image: require("../../assets/dekoracne.jpg"),
	},
];
