import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
	createStyles({
		products: {
			display: "grid",
			gap: "40px",
			padding: "50px",
			gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
		},
	})
);

export default useStyles;
