import { makeStyles } from "@material-ui/core";

import image from "../../assets/background-football.jpg";

const useStyles = makeStyles(() => ({
	main: {
		padding: "80px 0",
		display: "grid",
		gridTemplateColumns: "40% auto",
		backgroundImage: `url(${image})`,
		filter: "brightness(95%)",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
	},
	mainMobile: {
		padding: "100px 0",
		display: "grid",
		gridTemplateColumns: "auto",
		backgroundImage: `url(${image})`,
		filter: "brightness(95%)",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
	},
	gridContainer: {
		padding: "0 10%",
		marginBottom: "40px",
	},
	contactTile: {
		backgroundColor: "#0000008c !important",
		textAlign: "center",
	},
	mapContainer: {
		borderRadius: "8px",
		backgroundColor: "#0000008c",
		textAlign: "center",
		paddingTop: "20px",
		width: "80%",
		maxHeight: "450px",
	},
	whiteText: {
		color: "whitesmoke",
	},
	mapLink: {
		marginTop: "10px",
		borderRadius: "8px",
	},
}));

export default useStyles;
