import createMessageDescriptors from "../../lib/CommonLib/createMessageDescriptors.ts";

export default createMessageDescriptors("PRODUCT_DETAIL", {
	PRODUCTS: "Produkty",
	INDOOR_SPORT_SURFACES: "Vnútorné športové povrchy",
	INDOOR_SPORT_SURFACES_DESRIPTION:
		"Sme schopní pre Vás navrhnúť a zrealizovať akýkoľvek systém od školských či amatérskych objektov až po profesionálne haly. Ponúkame povrchy na báze PVC, alebo tvrdé tzv. hard povrchy. Do fitnes centier a/alebo hráčskych šatní  dodávame takmer výhradne povrchy na báze gumy. Ide o povrchy kobercového typu vyrobeného zo syntetického kaučuku, polyuretánu a vysoko kvalitných gumených recyklátov v kombinácii a farebnými EPDM granulátmi.",
	MULTIPURPOSE_FIELDS: "Multifunkčné ihriská",
	MULTIPURPOSE_FIELDS_DESCRIPTION:
		"Viacúčelové ihrisko FunnySport umožňuje prevádzkovať tieto športy: hádzaná, tenis, streetball, nohejbal, basketbal, golf, hokejbal, volejbal, gymnastika, florbal, bedminton, korčuľovanie, ľadový hokej, mini futbal. Takéto ihriská je možné využívať celoročne a vyznačujú sa dlhou životnosťou. Sú ideálnym zariadením navrhnutým pre mestá a obce, školy, rekreačné zariadenia, hotely a športové kluby, kde sa požaduje široké športové využitie na minimálnej ploche.",
	FOOTBALL_PITCH: "Futbalové ihriská",
	FOOTBALL_PITCH_DESCRIPTION:
		"Výrobcom futbalových umelých trávnikov je osvedčená firma s viac ako 30-ročnými skúsenosťami na tomto trhu, ktorý je vysoko náročný na kvalitu a životnosť. Jej futbalový športový systém spĺňa kvalitatívne požiadavky podľa parametrov FIFA.",
	ATHLETICS: "Atletika",
	ATHLETICS_DESCRIPTION:
		"EPDM športový povrch je medzinárodne uznávaný federáciou IAAF (medzinárodná atletická federácia) ako povrch pre atletické disciplíny ako sú beh, skok do výšky, skok do diľky, vrhačské sektory, alebo viacboje. Rovnako je možné ho však využiť pri výstavbe viacúčelového ihriska ako jedna z alternatív voči umelému trácniku. Športový povrch spĺňa všetky fyzikálne vlastnosti požadované pre uvedený povrch, či už je to UV stabilita, nízka oderuvzdordnosť a vysoká pružnosť.",
	KIDS_PLAYGROUNDS: "Detské ihriská",
	KIDS_PLAYGROUNDS_DESCRIPTION:
		"Ponúkame širokú škálu výrobkov od našich overených dodávateľov. Všetky herné zariadenia sú certifikované podľa normy EN 1176 a konštruované tak, aby čo najviac odolávali prípadnému vandalizmu. Popri prvoradej bezpečnosti je našou spoločnou snahou vybudovať ihriská, ktoré budú deťom prinášať radosť.",
	OUTSIDE_FITNESS_AND_WORKOUT: "Vonkajší fitness a workout",
	OUTSIDE_FITNESS_AND_WORKOUT_DESCRIPTION:
		"V poslednej dobe sa do popredia čoraz častejšie dostáva význam pohybu a možnosti pohybových/cvičebných aktivít realizovaných vonku. Za týmto účelom sa v rámci oddychových zón budujú fitness a workout ihriská, ktoré získavajú svoju obľubu nielen u mladých ľudí. Ich výhodou je variabilita a využiteľnosť pre všetky partie tela. ",
	MAITANANCE: "Údržba",
	MAITANANCE_DESCRIPTION:
		"V priebehu užívania ihrísk s umelým trávnikom sa vlastnosti povrchu menia a takisto ho ovplyvňujú aj vonkajšie poveternostné vplyvy a spôsob užívania. Pri zvýšenej športovej činnosti sa umelý trávnik udupáva, čo môže mať za následok zníženie kvality povrchu. Naopak pri zníženej činnosti vzniká riziko, že umelý trávnik zarastie machom príp. inou burinou. Z týchto hľadísk je pravidelná údržba ihriska veľmi dôležitá. Celková údržba sa spravidla vykonáva na jar. Disponujeme rôznymi údržbovými mechanizmami a prostriedkami.",
	IMPACT_SURFACES_AND_SAFETY_SURFACES: "Dopadové plochy a bezpečnostné povrchy",
	IMPACT_SURFACES_AND_SAFETY_SURFACES_DESCCRIPTION:
		"Sú dôležitou súčasťou dodávky detských ihrísk a herných prvkov s účelom zabrániť resp. minimalizovať úrazy detí. Jedná sa prevažne o pryžové podložky rôznej hrúbky v závislosti od výšky dopadu daného herného prvku. Tieto podložky spĺňajú normy EN 1176 a 1177. Vyhotovené sú prevažne vo farebnom prevedení - čierna, červená, zelená, sivá. Ďalšou alternatívou dopadových plôch sú zatrávňovacie rohože, ktoré sa nechajú prerásť trávou, alebo iné ako napr. umelý trávnik, liata guma, štrkopiesok, mulčovacia kôra.",
	DECOR_SURFACES: "Dekoračné trávniky",
	DECOR_SURFACES_DESCRIPTION:
		"Tieto sa v poslednom období dostali do popredia mnohých zákazníkov. Dekoračné trávniky nájdu svoje využitie v priestoroch bazénov, okolo domov, na detské ihriská. Majú celoročné využitie, sú príjemné na vzhľad aj chôdzu, nenáročné na údržbu.",
});
