import React from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Switch } from "react-router-dom";
import Routes from "./app/Routes";
import { AppBar } from "./lib/CommonTech/AppBar/AppBar";

function App(): JSX.Element {
	return (
		<BrowserRouter>
			<IntlProvider locale="sk-SK" defaultLocale="sk-SK">
				<AppBar />
				<Switch>
					<Routes />
				</Switch>
			</IntlProvider>
		</BrowserRouter>
	);
}

export default App;

// const matchesChartBreakpoint = useMediaQuery(theme.breakpoints.up("xl"));
