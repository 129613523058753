import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	container: {
		margin: "50px 20%",
		display: "grid",
		gridTemplateColumns: "auto auto auto ",
	},
	mobileContainer: {
		margin: "50px",
		display: "grid",
		gridTemplateColumns: "auto",
		textAlign: "center",
	},
	img: {
		cursor: "pointer",
	},
}));

export default useStyles;
