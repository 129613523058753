import React from "react";
import { Button, Fade, Menu, MenuItem } from "@material-ui/core";
import classnames from "classnames";
import { Link } from "react-router-dom";
import useStyles from "./mobileAppBar.style";
import { Menu as MenuIcon } from "@material-ui/icons";
import { MenuElements } from "../MenuElements";

export interface MobileAppBarProps {
	location: string;
}

export const MobileAppBar = ({ location }: MobileAppBarProps): JSX.Element => {
	const styles = useStyles();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				id="fade-button"
				aria-controls="fade-menu"
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
			>
				<MenuIcon />
			</Button>

			<Menu
				id="fade-menu"
				MenuListProps={{
					"aria-labelledby": "fade-button",
				}}
				anchorEl={anchorEl}
				open={open}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				onClose={handleClose}
				TransitionComponent={Fade}
			>
				{MenuElements.map((element) => (
					<MenuItem
						key={element.title}
						id={element.title}
						onClick={handleClose}
						color="inherit"
						className={classnames(styles.linkBtn, location === element.route && styles.activeLinkBtn)}
					>
						<Link to={element.route} className={styles.linkStyle}>
							{element.title}
						</Link>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
