import { defineMessages, MessageDescriptor } from "react-intl";
import { mapObjIndexed } from "ramda";

function createMessageDescriptors<I extends keyof S & string, S extends Record<I, string>>(
	id: string,
	messages: S
): Record<I, MessageDescriptor> {
	const input = mapObjIndexed((value: string, key: I) => ({ id: `${id}.${key}`, defaultMessage: value }), messages);
	return defineMessages(input);
}

export default createMessageDescriptors;
