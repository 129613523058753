import React, { ReactNode } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "./productContainer.styles";
import classNames from "classnames";
import AppHeader from "../../AppHeader";
import { ProductDetailImage } from "../ProductDetailImage";

export interface ProductContainerProps {
	appHeaderLabel: string;
	description: string;
	img: string;
	imgAlt: string;
	children?: ReactNode;
}

export interface PapaProps {}

const ProductContainer = ({ children, appHeaderLabel, description, img, imgAlt }: ProductContainerProps): JSX.Element => {
	const theme = useTheme();
	const styles = useStyles();
	const matches = useMediaQuery(theme.breakpoints.up("md"));

	return (
		<>
			<AppHeader headerText={appHeaderLabel} />
			<div className={classNames(!matches ? styles.mobileContainer : styles.container)}>
				<p className={styles.textInfo}>{description}</p>
				<ProductDetailImage img={img} alt={imgAlt} />
			</div>
			{children}
		</>
	);
};

export default ProductContainer;
