import React from "react";
import { Container, Typography } from "@material-ui/core";

import teamPhoto from "../../assets/teamFs.jpg";
import certificate from "../../assets/certificate.png";
import certificate2 from "../../assets/certificate2.png";
import AppHeader from "../../lib/CommonTech/AppHeader";

export const AboutUs = (): JSX.Element => {
	return (
		<>
			<AppHeader headerText="O nás" />

			<Container maxWidth="md">
				<Typography variant="h5" align="center" style={{ color: "black", margin: "40px" }}>
					Spoločnosť FUNNY SPORT SLOVENSKO s.r.o. pôsobí na slovenskom trhu od roku 2000 a nosnú časť výrobného programu má
					dodávku a montáž rôznych športových plôch, športovísk, ihrísk a to hlavne v oblasti športových povrchov na báze dreva,
					gumy, kobercov a povlakových krytín. Na slovenskom trhu zastupujeme mnoho osvedčených dodávateľov športových
					interiérových a exteriérových povrchov. V segmente umelých trávnikov zastupujeme holandského výrobcu- firmu EDEL GRASS
					B.V. Úzko spolupracujeme a dodávame športové povrchy aj od iných osvedčených výrobcov, ako napr. Gerflor, Graboplast,
					Bolidt, Polytan, Courtsol, Schmitz, Regupol.
				</Typography>
			</Container>

			<div style={{ margin: "0 30px" }}>
				<img src={teamPhoto} alt="Funny sport team" style={{ width: "100%" }} />
			</div>

			<Container style={{ padding: "0", marginTop: "30px" }}>
				<Typography variant="h6" align="center">
					Všetky nami dodávané materiály sú certifikované podľa aktuálnych platných noriem pre jednotlivé športové odvetvia.
				</Typography>

				<Typography variant="h6" align="center">
					Naša spoločnosť je držiteľom certifikátov ako napr. ISO, ESTO a nachádzame sa aj v zozname podnikateľov UVO.
				</Typography>

				<div style={{ margin: "30px", padding: "0 30px", textAlign: "center" }}>
					<img src={certificate} alt="certificate" height={280} />
					<img src={certificate2} alt="certificate2" />
				</div>
			</Container>
		</>
	);
};
