import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
	createStyles({
		primaryNav: {
			margin: "0",
			listStyleType: "none",
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			marginRight: "1.5em",
		},
		linkBtn: {
			height: "3.5em",
			padding: "0px",
		},
		activeLink: {
			color: "#78c145",
		},
		linkStyle: {
			color: "#107e40",
			fontSize: "16px",
			textTransform: "capitalize",
			textDecoration: "none",
			padding: "0.2em 0.8em",
		},
		liStyle: {
			textDecoration: "none",
		},
	})
);

export default useStyles;
