export const MenuElements = [
	{
		title: "Domov",
		route: "/",
	},
	{
		title: "Produkty",
		route: "/produkty",
	},
	{
		title: "Referencie",
		route: "/referencie",
	},
	{
		title: "O nás",
		route: "/o-nas",
	},
	{
		title: "Kontakt",
		route: "/kontakt",
	},
];
