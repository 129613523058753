import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
	createStyles({
		linkBtn: {
			height: "3.5em",
			padding: "0px",
		},
		activeLinkBtn: {
			backgroundColor: "#7aff9d80",
		},
		linkStyle: {
			color: "black",
			fontSize: "16px",
			textTransform: "capitalize",
			textDecoration: "none",
			padding: "0.2em 0.8em",
		},
		liStyle: {
			textDecoration: "none",
		},
	})
);

export default useStyles;
