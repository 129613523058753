import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	container: {
		marginTop: "5%",
		display: "grid",
		margin: "0 10%",
		gridTemplateColumns: "40% auto",
	},
	mobileContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	textInfo: {
		textAlign: "center",
		fontSize: "24px",
	},
}));

export default useStyles;
