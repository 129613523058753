const routeDefinitions = {
	home: "/",
	aboutUs: "/o-nas",
	contact: "/kontakt",
	references: "/referencie",
	products: "/produkty",
	productDetail: "/produkt/:id",
};

export default routeDefinitions;
