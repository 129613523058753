import React from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import productDetailStrings from "./productDetailStrings";
import { ProductContainer } from "../../lib/CommonTech/Products/ProductContainer";

import vnutrornyPovrch from "./../../assets/BA ZŠ Bieloruská.jpg";
import futbalove_ihrisko from "./../../assets/futbalovve_ihrisko.jpg";
import multifunkcne_ihrisko from "./../../assets/multiIhrisko.jpg";
import detske_ihrisko from "./../../assets/detske_Ihrisko2.jpg";
import workout_zona from "./../../assets/workout.jpg";
import udrzba_ihrisk from "./../../assets/udrzba_ihrisk.jpg";
import atletika from "../../assets/atletika.jpg";
import dopad from "../../assets/multifunkcne.jpg";
import dekoracne from "./../../assets/dekoracne.jpg";

export interface ProductDetailProps {}

const ProductDetail = (): JSX.Element => {
	const location = useLocation();
	const intl = useIntl();

	const label = location.pathname.slice(9);
	switch (label) {
		case "vnutorne-sportove-povrchy":
			return (
				<ProductContainer
					appHeaderLabel={intl.formatMessage(productDetailStrings.INDOOR_SPORT_SURFACES)}
					description={intl.formatMessage(productDetailStrings.INDOOR_SPORT_SURFACES_DESRIPTION)}
					img={vnutrornyPovrch}
					imgAlt={intl.formatMessage(productDetailStrings.INDOOR_SPORT_SURFACES)}
				/>
			);

		case "multifunkcne-ihriska":
			return (
				<ProductContainer
					appHeaderLabel={intl.formatMessage(productDetailStrings.MULTIPURPOSE_FIELDS)}
					description={intl.formatMessage(productDetailStrings.MULTIPURPOSE_FIELDS_DESCRIPTION)}
					img={multifunkcne_ihrisko}
					imgAlt={intl.formatMessage(productDetailStrings.MULTIPURPOSE_FIELDS)}
				/>
			);

		case "futbalove-ihriska":
			return (
				<ProductContainer
					appHeaderLabel={intl.formatMessage(productDetailStrings.FOOTBALL_PITCH)}
					description={intl.formatMessage(productDetailStrings.FOOTBALL_PITCH_DESCRIPTION)}
					img={futbalove_ihrisko}
					imgAlt={intl.formatMessage(productDetailStrings.FOOTBALL_PITCH)}
				/>
			);

		case "atletika":
			return (
				<ProductContainer
					appHeaderLabel={intl.formatMessage(productDetailStrings.ATHLETICS)}
					description={intl.formatMessage(productDetailStrings.ATHLETICS_DESCRIPTION)}
					img={atletika}
					imgAlt={intl.formatMessage(productDetailStrings.ATHLETICS)}
				/>
			);

		case "detske-ihriska":
			return (
				<ProductContainer
					appHeaderLabel={intl.formatMessage(productDetailStrings.KIDS_PLAYGROUNDS)}
					description={intl.formatMessage(productDetailStrings.KIDS_PLAYGROUNDS_DESCRIPTION)}
					img={detske_ihrisko}
					imgAlt={intl.formatMessage(productDetailStrings.KIDS_PLAYGROUNDS)}
				/>
			);

		case "vonkajsi-fitness-workout":
			return (
				<ProductContainer
					appHeaderLabel={intl.formatMessage(productDetailStrings.OUTSIDE_FITNESS_AND_WORKOUT)}
					description={intl.formatMessage(productDetailStrings.OUTSIDE_FITNESS_AND_WORKOUT_DESCRIPTION)}
					img={workout_zona}
					imgAlt={intl.formatMessage(productDetailStrings.OUTSIDE_FITNESS_AND_WORKOUT)}
				/>
			);
		case "udrzba":
			return (
				<ProductContainer
					appHeaderLabel={intl.formatMessage(productDetailStrings.MAITANANCE)}
					description={intl.formatMessage(productDetailStrings.MAITANANCE_DESCRIPTION)}
					img={udrzba_ihrisk}
					imgAlt={intl.formatMessage(productDetailStrings.MAITANANCE)}
				/>
			);
		case "dopadove-plochy-bezpecnostne-povrchy":
			return (
				<ProductContainer
					appHeaderLabel={intl.formatMessage(productDetailStrings.IMPACT_SURFACES_AND_SAFETY_SURFACES)}
					description={intl.formatMessage(productDetailStrings.IMPACT_SURFACES_AND_SAFETY_SURFACES_DESCCRIPTION)}
					img={dopad}
					imgAlt={intl.formatMessage(productDetailStrings.IMPACT_SURFACES_AND_SAFETY_SURFACES)}
				/>
			);
		case "dekorativne-plochy":
			return (
				<ProductContainer
					appHeaderLabel={intl.formatMessage(productDetailStrings.DECOR_SURFACES)}
					description={intl.formatMessage(productDetailStrings.DECOR_SURFACES_DESCRIPTION)}
					img={dekoracne}
					imgAlt={intl.formatMessage(productDetailStrings.DECOR_SURFACES)}
				/>
			);

		default:
			return <></>;
	}
};

export default ProductDetail;
