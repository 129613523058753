import React from "react";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import productStrings from "../productStrings";
import useStyles from "./prooductHeader.styles";

export interface ProductHeaderProps {
	id: string;
}

const ProductHeader = ({ id }: ProductHeaderProps): JSX.Element => {
	const styles = useStyles();

	const getText = (id: string) => {
		switch (id) {
			case "MULTIPURPOSE_FIELDS":
				return <FormattedMessage {...productStrings.MULTIPURPOSE_FIELDS} />;
			case "INDOOR_SPORT_SURFACES":
				return <FormattedMessage {...productStrings.INDOOR_SPORT_SURFACES} />;
			case "FOOTBALL_PITCH":
				return <FormattedMessage {...productStrings.FOOTBALL_PITCH} />;
			case "ATHLETICS":
				return <FormattedMessage {...productStrings.ATHLETICS} />;
			case "KIDS_PLAYGROUNDS":
				return <FormattedMessage {...productStrings.KIDS_PLAYGROUNDS} />;
			case "OUTSIDE_FITNESS_AND_WORKOUT":
				return <FormattedMessage {...productStrings.OUTSIDE_FITNESS_AND_WORKOUT} />;
			case "MAITANANCE":
				return <FormattedMessage {...productStrings.MAITANANCE} />;
			case "IMPACT_SURFACES_AND_SAFETY_SURFACES":
				return <FormattedMessage {...productStrings.IMPACT_SURFACES_AND_SAFETY_SURFACES} />;
			case "DECOR_SURFACES":
				return <FormattedMessage {...productStrings.DECOR_SURFACES} />;
			default:
				return <></>;
		}
	};

	return (
		<div className={styles.headerTitle}>
			<Typography variant="h5" className={styles.textStyle}>
				{getText(id)}
			</Typography>
		</div>
	);
};

export default ProductHeader;
