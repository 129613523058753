import React from "react";
import { useIntl } from "react-intl";
import { Product } from "../../lib/CommonTech/Products";
import AppHeader from "../../lib/CommonTech/AppHeader";
import useStyles from "./products.styles";
import { ProductsArray } from "./ProductsArray";
import productsStrings from "./productsStrings";

const Products = () => {
	const styles = useStyles();
	const intl = useIntl();
	return (
		<>
			<AppHeader headerText={intl.formatMessage(productsStrings.PRODUCTS)} />

			<div className={styles.products}>
				{ProductsArray.map((item) => (
					<Product key={item.id} title={item.id} image={item.image} route={item.route} />
				))}
			</div>
		</>
	);
};

export default Products;
