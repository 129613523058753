import React from "react";
import { Button, Typography } from "@material-ui/core";
import classnames from "classnames";
import { Link } from "react-router-dom";
import useStyles from "./desktopAppBar.styles";
import { MenuElements } from "../MenuElements";
import { toUpper } from "ramda";

export interface DesktopAppBarProps {
	location: string;
}

export const DesktopAppBar = ({ location }: DesktopAppBarProps): JSX.Element => {
	const styles = useStyles();

	return (
		<ul className={styles.primaryNav}>
			{MenuElements.map((element) => (
				<li className={styles.liStyle} key={element.title}>
					<Button color="inherit" className={styles.linkBtn}>
						<Link to={element.route} className={styles.linkStyle}>
							<Typography variant="h5" className={classnames(location === element.route && styles.activeLink)}>
								{toUpper(element.title)}
							</Typography>
						</Link>
					</Button>
				</li>
			))}
		</ul>
	);
};
