import React from "react";
import { Container, Grid, Typography, CardContent, Card, CardHeader, useMediaQuery, useTheme } from "@material-ui/core";

import mapImg from "../../assets/funnysportMap.jpg";
import AppHeader from "../../lib/CommonTech/AppHeader";
import useStyles from "./contact.styles";
import contactStrings from "./contactStrings";
import { useIntl } from "react-intl";
import classnames from "classnames";

const contacts = [
	{
		title: "tel. / fax. / záznamník",
		description: ["+421 37 / 6533070"],
	},
	{
		title: "Mgr. Petra Konopová",
		description: ["Projektový manažér", "+421 918 951 731"],
	},
	{
		title: "Patrik Jedlička",
		description: ["Výrobno - technický manažér", "+421 918 415 091"],
	},
	{
		title: "Ing. Roman Královič ",
		description: ["obchodno - technický manažér", "+421 905 911 726"],
	},
];

export const Contact = (): JSX.Element => {
	const styles = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("md"));
	const intl = useIntl();
	return (
		<>
			<AppHeader headerText={intl.formatMessage(contactStrings.CONTACT)} />

			<div className={classnames(!matches ? styles.mainMobile : styles.main)}>
				<Grid container spacing={3} direction="column" className={styles.gridContainer}>
					{contacts.map((con) => (
						<Grid item key={con.title}>
							<Card className={styles.contactTile}>
								<CardHeader
									className={styles.whiteText}
									title={con.title}
									titleTypographyProps={{ align: "center" }}
									subheaderTypographyProps={{
										align: "center",
									}}
								/>
								<CardContent>
									<div>
										{con.description.map((line) => (
											<Typography className={styles.whiteText} align="center" key={line}>
												{line}
											</Typography>
										))}
									</div>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>

				<Container maxWidth="sm" className={styles.mapContainer}>
					<Typography className={styles.whiteText} variant="h5">
						FUNNY SPORT SLOVENSKO s.r.o.
					</Typography>
					<Typography className={styles.whiteText} variant="h6">
						Dlhá 89 B, Nitra 94901
					</Typography>
					<Typography className={styles.whiteText} variant="h6">
						funnysport@funnysport.sk
					</Typography>

					<a href="http://maps.google.sk/maps?q=FUNNY+SPORT+SLOVENSKO+s.r.o.&amp;hl=en&amp;ll=48.303451,18.118622&amp;spn=0.007965,0.01929&amp;georestrict=input_srcid:93a40e6eac40c897&amp;view=map&amp;cid=16748211844680945597&amp;hq=FUNNY+SPORT+SLOVENSKO+s.r.o.&amp;t=h&amp;z=16&amp;iwloc=A">
						<img className={styles.mapLink} src={mapImg} alt="funnysportMapa" />
					</a>
				</Container>
			</div>
		</>
	);
};
