import React from "react";
import { Container, Typography, Box } from "@material-ui/core";
import LightboxGallery from "../../lib/CommonTech/LightboxGallery";

export const References = (): JSX.Element => {
	return (
		<>
			<Box
				sx={{
					bgcolor: "black",
					pt: 8,
					pb: 6,
				}}
			>
				<Container maxWidth="sm">
					<Typography component="h1" variant="h2" align="center" style={{ color: "green" }} gutterBottom>
						Referencie
					</Typography>
				</Container>
			</Box>

			<Container maxWidth="md">
				<Typography variant="h6" align="center" style={{ marginTop: "30px" }}>
					Galéria referencií
				</Typography>
			</Container>

			<LightboxGallery />
		</>
	);
};
