import React from "react";
import { Carousel as CarouselMui } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import futbalove_ihrisko from "../../../assets/futbalovve_ihrisko.jpg";
import multifunkcne_ihrisko from "../../../assets/multiIhrisko.jpg";
import atletika from "../../../assets/atletika.jpg";
import workout_zona from "../../../assets/workout.jpg";
import vnutorne from "../../../assets/vnutorne.jpg";

import useStyles from "./carousel.styles";

export const Carousel = (): JSX.Element => {
	const styles = useStyles();
	// const theme = useTheme();
	// const matches = useMediaQuery(theme.breakpoints.up("md"));

	// TU BUDU LEN CCA 3 obrazky krasne neskutocne + treba pridat namiesto stringov mess descriptor

	return (
		<CarouselMui autoPlay stopOnHover infiniteLoop showStatus={false} showThumbs={false}>
			<div className={styles.block}>
				<img alt="futbalove ihrisko" height="600px" src={futbalove_ihrisko} style={{ objectFit: "cover" }} />
				<p className={styles.legend}>
					Výrobcom futbalových umelých trávnikov je osvedčená firma s viac ako 30-ročnými skúsenosťami na tomto trhu, ktorý je
					vysoko náročný na kvalitu a životnosť. Jej futbalový športový systém spĺňa kvalitatívne požiadavky podľa parametrov
					FIFA.
				</p>
			</div>

			<div className={styles.block}>
				<img alt="Vnútorné športové povrchy" height="600px" src={vnutorne} style={{ objectFit: "cover" }} />
				<p className={styles.legend}>
					Sme schopní pre Vás navrhnúť a zrealizovať akýkoľvek systém od školských či amatérskych objektov až po profesionálne
					haly. Ponúkame povrchy na báze PVC, alebo tvrdé tzv. hard povrchy. Do fitnes centier a/alebo hráčskych šatní dodávame
					takmer výhradne povrchy na báze gumy.
					{/* Ide o povrchy kobercového typu vyrobeného zo syntetického kaučuku, polyuretánu a
					vysoko kvalitných gumených recyklátov v kombinácii a farebnými EPDM granulátmi. */}
				</p>
			</div>

			<div className={styles.block}>
				<img alt="multifunkcne ihrisko" height="600px" src={multifunkcne_ihrisko} style={{ objectFit: "cover" }} />
				<p className={styles.legend}>
					Viacúčelové ihrisko FunnySport umožňuje prevádzkovať tieto športy: hádzaná, tenis, streetball, nohejbal, basketbal,
					golf, hokejbal, volejbal, gymnastika, florbal, bedminton, korčuľovanie, ľadový hokej, mini futbal. Takéto ihriská je
					možné využívať celoročne a vyznačujú sa dlhou životnosťou.
					{/* Sú ideálnym zariadením navrhnutým pre mestá a obce, školy,
					rekreačné zariadenia, hotely a športové kluby, kde sa požaduje široké športové využitie na minimálnej ploche. */}
				</p>
			</div>

			<div className={styles.block}>
				<img alt="atleticka draha" src={atletika} height="600px" style={{ objectFit: "cover" }} />
				<p className={styles.legend}>
					EPDM športový povrch je medzinárodne uznávaný federáciou IAAF (medzinárodná atletická federácia) ako povrch pre
					atletické disciplíny ako sú beh, skok do výšky, skok do diľky, vrhačské sektory, alebo viacboje. Rovnako je možné ho
					však využiť pri výstavbe viacúčelového ihriska ako jedna z alternatív voči umelému trávniku.
					{/* Športový povrch spĺňa
					všetky fyzikálne vlastnosti požadované pre uvedený povrch, či už je to UV stabilita, nízka oderuvzdordnosť a vysoká
					pružnosť. */}
				</p>
			</div>

			{/* <div className={styles.block}>
				<img alt="detske ihriska" src={detske_ihrisko} height="600px" style={{ objectFit: "cover" }} />
				<p className={styles.legend}>
					Ponúkame širokú škálu výrobkov od našich overených dodávateľov. Všetky herné zariadenia sú certifikované podľa normy EN
					1176 a konštruované tak, aby čo najviac odolávali prípadnému vandalizmu. Popri prvoradej bezpečnosti je našou spoločnou
					snahou vybudovať ihriská, ktoré budú deťom prinášať radosť.
				</p>
			</div> */}

			<div className={styles.block}>
				<img alt="workout zona" src={workout_zona} width="600px" height="600px" style={{ objectFit: "cover" }} />
				<p className={styles.legend}>
					V poslednej dobe sa do popredia čoraz častejšie dostáva význam pohybu a možnosti pohybových/cvičebných aktivít
					realizovaných vonku. Za týmto účelom sa v rámci oddychových zón budujú fitness a workout ihriská, ktoré získavajú svoju
					obľubu nielen u mladých ľudí. Ich výhodou je variabilita a využiteľnosť pre všetky partie tela.
				</p>
			</div>

			{/* <div className={styles.block}>
				<img alt="Dekoračné trávniky" src={dekoracne} height="600px" style={{ objectFit: "cover" }} />
				<p className={styles.legend}>
					Tieto sa v poslednom období dostali do popredia mnohých zákazníkov. Dekoračné trávniky nájdu svoje využitie v
					priestoroch bazénov, okolo domov, na detské ihriská. Majú celoročné využitie, sú príjemné na vzhľad aj chôdzu, nenáročné
					na údržbu.
				</p>
			</div> */}

			{/* <div className={styles.block}>
				<img alt="Údržby ihrísk" src={udrzba_ihrisk} width="600px" height="600px" style={{ objectFit: "cover" }} />
				<p className={styles.legend}>
					V priebehu užívania ihrísk s umelým trávnikom sa vlastnosti povrchu menia a takisto ho ovplyvňujú aj vonkajšie
					poveternostné vplyvy a spôsob užívania. Pri zvýšenej športovej činnosti sa umelý trávnik udupáva, čo môže mať za
					následok zníženie kvality povrchu. Naopak pri zníženej činnosti vzniká riziko, že umelý trávnik zarastie machom príp.
					inou burinou. Z týchto hľadísk je pravidelná údržba ihriska veľmi dôležitá. Celková údržba sa spravidla vykonáva na jar.
					Disponujeme rôznymi údržbovými mechanizmami a prostriedkami.
				</p>
			</div> */}
		</CarouselMui>
	);
};
