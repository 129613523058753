import React from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ProductHeader } from "./ProductHeader";
import useStyles from "./products.styles";
import classnames from "classnames";

export interface ProductProps {
	title: string;
	image: any;
	route: string;
}

const Product = ({ title, image, route }: ProductProps): JSX.Element => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));
	const styles = useStyles();

	return (
		<Link to={"produkt/" + route} className={styles.product}>
			<ProductHeader id={title} />
			<img className={classnames(!matches ? styles.imageMobile : styles.image)} src={image.default} alt={title} />
		</Link>
	);
};

export default Product;
