import createMessageDescriptors from "../../lib/CommonLib/createMessageDescriptors.ts";

export default createMessageDescriptors("PRODUCTS", {
	PRODUCTS: "Produkty",
	FOOTBALL_FIELDS: "Futbalové ihriská",
	TENIS_FIELDS: "Tenisové ihriská",
	DECORATIVE_SURFACES: "Okrasné povrchy",
	MULTIPURPOSE_FIELDS: "Viacúčelové ihriská",
	GYMS_HALLS: "Telocvične a Haly",
	ATHLETICS: "Atletika",
	HOCKEYBALL: "Hokejbal",
	MAITANANCE_FIELDS: "Údržba ihrísk",
});
