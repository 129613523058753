import React, { useState } from "react";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";

import futbalove_ihrisko from "../../../assets/futbalovve_ihrisko.jpg";
import multifunkcne_ihrisko from "../../../assets/multifunkcne.jpg";
import multi from "../../../assets/multiIhrisko.jpg";
import atletika from "../../../assets/atletika.jpg";
import detske_ihrisko from "../../../assets/detske_ihrisko.jpg";
import detske_ihrisko2 from "../../../assets/detske_Ihrisko2.jpg";
import workout_zona from "../../../assets/workout.jpg";
import dekoracne from "../../../assets/dekoracne.jpg";
import vnutorne from "../../../assets/vnutorne.jpg";
import tenisoveIhrisko from "../../../assets/tenisoveIhrisko.jpg";
import telocvicna from "../../../assets/BA ZŠ Bieloruská.jpg";
import ihrisko from "../../../assets/ihrisko.jpg";
import workout from "../../../assets/workout.jpg";

import useStyles from "./lightboxGallery.styles";
import { useTheme, useMediaQuery } from "@material-ui/core";
import classNames from "classnames";

const images = [
	workout,
	multi,
	ihrisko,
	telocvicna,
	detske_ihrisko2,
	futbalove_ihrisko,
	multifunkcne_ihrisko,
	atletika,
	detske_ihrisko,
	workout_zona,
	dekoracne,
	vnutorne,
	tenisoveIhrisko,
];

const LightboxGallery = (): JSX.Element => {
	const [isOpen, setOpen] = useState(false);
	const [imgIndex, setImgIndex] = useState(0);

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("md"));
	const styles = useStyles();

	return (
		<>
			<div className={classNames(!matches ? styles.mobileContainer : styles.container)}>
				{images.map((url, index) => {
					return (
						<div
							key={index}
							onClick={() => {
								setOpen(true);
								setImgIndex(index);
							}}
						>
							<img className={styles.img} src={url} height={160} width={200} alt={"ihrisko" + index} />
						</div>
					);
				})}
			</div>

			{isOpen && (
				<Lightbox
					mainSrc={images[imgIndex]}
					nextSrc={images[(imgIndex + 1) % images.length]}
					prevSrc={images[(imgIndex + images.length - 1) % images.length]}
					onCloseRequest={() => setOpen(false)}
					onMovePrevRequest={() => setImgIndex((imgIndex + images.length - 1) % images.length)}
					onMoveNextRequest={() => setImgIndex((imgIndex + 1) % images.length)}
				/>
			)}
		</>
	);
};

export default LightboxGallery;
