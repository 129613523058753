import React from "react";
import { Box, Container, Typography } from "@material-ui/core";

export interface AppHeaderProps {
	headerText: string;
}

const AppHeader = ({ headerText }: AppHeaderProps): JSX.Element => {
	return (
		<Box
			sx={{
				bgcolor: "black",
				pt: 8,
				pb: 6,
			}}
		>
			<Container maxWidth="sm">
				<Typography component="h1" variant="h2" align="center" style={{ color: "green" }} gutterBottom>
					{headerText}
				</Typography>
			</Container>
		</Box>
	);
};

export default AppHeader;
