import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	product: {
		cursor: "pointer",
		width: "100%",
		textDecoration: "none",
		position: "relative",
		textAlign: "center",
	},
	imageMobile: {
		width: "100%",
		height: "220px",
	},
	image: {
		width: "100%",
		height: "280px",
		borderRadius: "2px",
		filter: "brightness(60%)",
	},
}));

export default useStyles;
