import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	imgBox: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	imgBoxMobile: {
		width: "250px",
		height: "150px",
		objectFit: "contain",
	},
	imgStyle: {
		objectFit: "contain",
		width: "500px",
		height: "300px",
	},
}));

export default useStyles;
