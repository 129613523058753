import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "./appBar.styles";
import { DesktopAppBar } from "./DesktopAppBar/DesktopAppBar";
import { Link, useLocation } from "react-router-dom";

import logo from "../../../assets/FunnySportLogo.png";

import { MobileAppBar } from "./MobileAppBar/MobileAppBar";

export const AppBar = (): JSX.Element => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("md"));
	const styles = useStyles();

	const location = useLocation();

	return (
		<div className={!matches ? styles.mobileNavigation : styles.navigation}>
			<Link to="/" className={styles.logo}>
				<img alt="funnysport-logo" src={logo} style={{ height: !matches ? "36px" : "150px" }} />
			</Link>

			{!matches ? <MobileAppBar location={location.pathname} /> : <DesktopAppBar location={location.pathname} />}
		</div>
	);
};
