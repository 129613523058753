import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
	createStyles({
		block: {
			backgroundColor: "black",
			maxHeight: "65vh",
		},
		legend: {
			position: "absolute",
			bottom: "40px",
			left: "50%",
			marginLeft: "-45%",
			width: "90%",
			color: "#fff",
			backgroundColor: "#0606068f",
			textAlign: "center",
			padding: "10px",
			borderRadius: "8px",
		},
	})
);

export default useStyles;
